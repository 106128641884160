import { ComplexNavbar } from "../../components/ComplexNavbar";
import { Footer } from "../../components/Footer";

const PrivacyPolicy: React.FC = () => {
    return (
        <div className="xl:mt-4 flex flex-col items-center justify-center h-full">
            <ComplexNavbar />

            {/* CONTENT */}
            <div className={`max-w-screen-xl w-full px-4 h-full mt-8`}>
                <div className="font-bold text-2xl">
                    Privacy Policy
                </div>
                <div className="mt-2"></div>
                <div>
                Lorem ipsum odor amet, consectetuer adipiscing elit. Sociosqu metus massa; augue id facilisi consequat cursus risus. Lobortis taciti nulla pulvinar non nam quisque facilisi. Convallis massa bibendum imperdiet accumsan euismod feugiat conubia. Felis eros enim litora justo natoque himenaeos dui tempus curabitur. Lacus eget vivamus sit neque senectus rhoncus arcu quam. Tincidunt dui morbi cras netus felis rhoncus egestas inceptos quam.
                <br/>
                <br/>
                Cras felis in duis magna pulvinar eleifend. Purus maximus risus tincidunt curae euismod vulputate. Platea suscipit tincidunt ipsum mattis lobortis fames scelerisque. Tempus ultricies feugiat pretium hac facilisis amet lacinia. Eros velit nam ultricies taciti egestas tempus vel nam. Torquent nibh aenean in mi; hendrerit maximus dignissim risus. Potenti porta sodales lacus vivamus leo. Luctus habitant ante facilisis vestibulum libero suscipit semper himenaeos. Purus ac tempor rutrum tempor sollicitudin non consectetur.
                <br/>
                <br/>
                Sagittis luctus aliquet cursus taciti suspendisse luctus. Magnis taciti est dui aptent, lobortis placerat? Nec ornare imperdiet aliquet per scelerisque suspendisse magna convallis. Senectus cras sollicitudin lacus consectetur ridiculus nisl vestibulum. Nibh at aenean habitasse ullamcorper augue nostra dictumst, tristique proin. Congue vel pretium habitasse; mauris tortor arcu.
                <br/>
                <br/>
                Consequat aliquam nascetur fermentum dis ipsum viverra turpis. Cursus etiam cubilia dui penatibus, finibus sodales accumsan ut. Eleifend fermentum tempor vestibulum interdum; feugiat aliquet bibendum magna. Vivamus lobortis porttitor elit imperdiet enim eget magna mattis ornare. Euismod massa vivamus auctor lacinia vestibulum faucibus class faucibus. Lobortis ad blandit orci tempor laoreet fusce, nisl nostra fermentum.
                <br/>
                <br/>
                Ante tincidunt volutpat donec fames facilisi nisl velit. Dictum proin facilisi accumsan augue cras nisl fusce porta. Velit gravida dui auctor; taciti duis odio aptent. Aenean morbi malesuada id urna tristique fames sem. Fermentum ex quam mus sociosqu aliquet dictumst ridiculus. Facilisi condimentum ornare fermentum accumsan sed. Class ultricies ante blandit auctor eget. Placerat scelerisque vivamus vulputate viverra ullamcorper sem.
                <br/>
                <br/>
                Auctor felis tristique donec nec venenatis, id risus pharetra. Nunc mi pellentesque id in egestas erat imperdiet etiam ligula! Fames urna sem augue nulla donec iaculis. Dapibus senectus turpis ante imperdiet nascetur ultricies tristique consequat. Porttitor dignissim natoque ultricies ad nunc. Posuere adipiscing facilisi laoreet luctus orci quis tortor in egestas. Metus sapien cubilia tempus primis inceptos eleifend.
                <br/>
                <br/>
                Nostra lectus suscipit tellus eleifend malesuada. Risus ullamcorper cursus faucibus parturient libero tellus pulvinar natoque. Justo amet fusce urna iaculis donec adipiscing senectus. Nam ex nam hac habitasse porttitor imperdiet viverra. Sit pharetra risus elit molestie; adipiscing placerat efficitur potenti nam. Suspendisse a molestie gravida felis viverra id; per nibh. Accumsan molestie venenatis magna auctor fusce euismod tincidunt netus. Montes euismod porta mauris ornare ullamcorper.
                <br/>
                <br/>
                Dignissim nostra scelerisque mattis potenti lacus neque sagittis. Arcu ipsum feugiat ultricies scelerisque velit mattis hac. Nostra curae massa sagittis lectus tincidunt diam est. Curabitur lectus ullamcorper potenti a arcu. Himenaeos per sed inceptos ut dictumst. Bibendum per sodales finibus risus quis facilisi ultricies rutrum. Donec mus aliquet varius odio finibus aenean magna vivamus tristique. Feugiat tempor mattis egestas fringilla nisl litora; elementum purus potenti. Consequat hendrerit efficitur metus venenatis malesuada posuere fringilla egestas.
                <br/>
                <br/>
                Primis facilisis a lobortis vivamus scelerisque nibh. Elit porttitor sollicitudin vel tincidunt praesent habitant? Aptent pellentesque metus sed etiam, at ullamcorper dictumst viverra. Lacus tristique fermentum placerat pulvinar eleifend fringilla suspendisse. Convallis senectus habitant imperdiet adipiscing nisi bibendum. Porta iaculis elit habitasse in, ut ornare cras vulputate convallis. Himenaeos condimentum curabitur sit, sodales duis luctus. Erat sit dapibus magna ut magnis ultrices etiam. Id elementum vitae venenatis tempor conubia eros imperdiet hendrerit.
                <br/>
                <br/>
                Facilisi lorem tempus in aenean quam; convallis odio. Tempor dapibus cursus; montes varius eleifend commodo euismod. Nam vivamus nulla varius odio risus! Ac interdum ullamcorper justo; tellus augue ut proin. Ornare dignissim imperdiet non, feugiat primis est. Lobortis vehicula fusce vulputate nisl laoreet senectus litora. Sapien aenean tristique netus; conubia etiam pulvinar venenatis amet libero. Senectus vivamus finibus netus morbi orci proin neque nulla. Cras nibh dignissim penatibus condimentum fringilla tellus.
                </div>
            </div>

            <div className="mt-8 w-full border-[#21211] border-t-2">
                <div className="mt-4">
                    <Footer/>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy;