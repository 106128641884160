import { Typography } from "@material-tailwind/react";
import { ComplexNavbar } from "../components/ComplexNavbar";
import { Footer } from "../components/Footer";

const Contact: React.FC = () => {
    return (
        <div className="xl:mt-4 flex flex-col items-center justify-center h-full">
            <ComplexNavbar />

            {/* CONTENT */}
            <div className={`max-w-screen-xl w-full px-4 h-full mt-8 text-center`}>
                <div>
                    <Typography
                        variant="h5"
                        color="blue-gray"
                        className="mb-4 !text-base lg:!text-2xl"
                        >
                        Customer Care
                    </Typography>
                    <Typography
                        variant="h1"
                        color="blue-gray"
                        className="mb-4 !text-3xl lg:!text-5xl"
                        >
                        We&apos;re Here to Help
                    </Typography>
                    <Typography className="mb-10 font-normal !text-lg lg:mb-20 mx-auto max-w-3xl !text-gray-500">
                        Whether it&apos;s a question about our services, a request for
                        technical assistance, or suggestions for improvement, our team is
                        eager to hear from you.
                    </Typography>
                </div>
            </div>

            <div className="mt-8 w-full border-[#21211] border-t-2">
                <div className="mt-4">
                    <Footer/>
                </div>
            </div>
        </div>
    )
}

export default Contact;