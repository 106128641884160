import { useEffect, useState } from "react";
import { ComplexNavbar } from "../components/ComplexNavbar";
import CasePage, { Case, CaseVariant } from "../components/steps/Case";
import { Applications, DisplayApp } from "../components/steps/Applications";
import axios from "axios";
import { Build, CustomerInformation } from "../types";
import Review from "../components/steps/Review";
import { Button, IconButton, select } from "@material-tailwind/react";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import Checkout from "../components/steps/Checkout";
import { Footer } from "../components/Footer";

export type CaseSelection = {
    selectedCase: Case,
    variant: CaseVariant,
}

function CreateBuild () {
    const [apps, setApps] = useState<DisplayApp[]>();
    const [selectedCase, setCase] = useState<CaseSelection>();
    const [step, setStep] = useState<number>(0);
    const [build, setBuild] = useState<Build>();

    const [ram, setRAM] = useState<number>(-1);
    const [storage, setStorage] = useState<number>(-1);
    const [clientPrice, setClientPrice] = useState<number>(-1);

    const onAppSelect = (apps: DisplayApp[]) => {
        setApps(apps);
        setStep(1);
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [step])

    const onCaseSelect = (c: Case | undefined, v: CaseVariant) => {
        console.log(c?.name + " | " + v.hex);
        if (c)
            setCase({selectedCase: c, variant: v})
            
        const apiURL = process.env.REACT_APP_DEV ? "http://localhost:8000" : "https://api.adriftcomputers.com";

        const handleClick = async () => {
            try {
                const response = await axios.post(`${apiURL}/submit`, {
                    apps: apps,
                    case: c,
                    variant: v,
                }
                );
                setBuild(response.data.build);
            } catch (err) {
            console.error('Error creating checkout session:', err);
            }
        };
        handleClick();
    }

    const onSpecConfirm = (ram: number, storage: number, price: number) => {
        setRAM(ram);
        setStorage(storage)
        setClientPrice(price);

        setStep(3);
    }

    const onPurchase = (customer: CustomerInformation) => {
        const apiURL = process.env.REACT_APP_DEV ? "http://localhost:8000" : "https://api.adriftcomputers.com";

        const handleAPI = async () => {
            try {
                if (!selectedCase) {
                    console.log("No case selected");
                    return;
                }
                const response = await axios.post(`${apiURL}/submit`, {
                    apps: apps,
                    ram: ram,
                    storage: storage,
                    checkout: true,
                    case: selectedCase.selectedCase,
                    variant: selectedCase.variant,
                    price: clientPrice,

                    customer: customer
                }
                );
                if (response.data.url) {
                    window.location.href = response.data.url;
                }
            } catch (err) {
            console.error('Error creating checkout session:', err);
            }
        };
        handleAPI();
    }

    useEffect(() => {
        if (build) {
            setStep(2);
        }
    }, [build])

    useEffect(() => {
        console.log("Step: " + step)
    }, [step])

    const goBack = () => {
        console.log("Attempting to go back");
        if (step > 0) {
            setStep(curr => curr-1)
            
        }
    }

    /*
        {step === 0 && <Applications onContinue={onAppSelect}/>}
        {step === 1 && <CasePage onContinue={onCaseSelect}/>}
        {(step === 2 && build && selectedCase) && <Review build={build} selectedCase={selectedCase} onPurchase={onPurchase}></Review>}
    */

    return (
        <div className="xl:mt-4 flex flex-col items-center justify-center h-full">
            <ComplexNavbar />

            <div className={`${step === 2 || step === 3 ? 'max-w-screen-xl' : 'max-w-screen-2xl'} w-full px-4 h-full`}>
                {step > 0 && <>
                    <div className="ml-[-16px] xl:ml-0 mt-12">
                        <Button className="mt-2.5 flex flex-row items-center gap-2" variant="text" onClick={goBack}>
                            <ArrowLeftIcon className="mt-1 xl:mt-0 h-6 stroke-[#21211] stroke-2 mb-[2px]" />
                            <div className="mb-0.5">Back</div>
                        </Button>
                    </div>
                </>}
                <div className={`${step === 0 ? '' : 'hidden'}`}>
                    <Applications onContinue={onAppSelect}/>
                </div>
                <div className={`${step === 1 ? '' : 'hidden'}`}>
                    <CasePage onContinue={onCaseSelect}/>
                </div>
                <div className={`${step === 2 ? '' : 'hidden'}`}>
                    {(build && selectedCase) && <Review build={build} selectedCase={selectedCase} onPurchase={onSpecConfirm}></Review>}
                </div>
                {/*{(step === 2 && build && selectedCase) && <Review build={build} selectedCase={selectedCase} onPurchase={onPurchase}></Review>}*/}
                {(step === 3 && build && selectedCase) && <Checkout build={build} selectedCase={selectedCase} onPurchase={onPurchase} ram={ram} storage={storage} price={clientPrice}></Checkout>}
                
            </div>

            <div className="mt-8 w-full border-[#21211] border-t-2">
                <div className="mt-4">
                    <Footer/>
                </div>
            </div>
        </div>
    )
}

export default CreateBuild;